import React, { useState, useEffect } from 'react';
import emailjs from 'emailjs-com';
import orderService from '../api/orderService';
import { ClipboardIcon } from '@heroicons/react/outline';

const CreateRequest = () => {
    const initialFormData = {
        senderName: '',
        senderPhone: '',
        pickupAddress: '',
        packageType: '',
        packageDescription: '',
        receiverName: '',
        receiverPhone: '',
        deliveryAddress: '',
        deliveryReferences: '',
        paymentType: '',
        amount: '',
        paymentStatus: '',
        isShippingPaid: ''
    };

    const [formData, setFormData] = useState(initialFormData);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isFormValid, setIsFormValid] = useState(true);
    const [isError, setIsError] = useState(false);
    const [touched, setTouched] = useState({});
    const [orderNumber, setOrderNumber] = useState('');
    const [showInitialPopup, setShowInitialPopup] = useState(true);

    useEffect(() => {
        if (!isModalOpen && !isError) {
            setFormData(initialFormData);
            setTouched({});
        }
    }, [isModalOpen, isError]);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
        setTouched({ ...touched, [e.target.name]: true });
    };

    const validateForm = () => {
        const { senderName, senderPhone, pickupAddress, packageType, packageDescription, receiverName, receiverPhone, deliveryAddress, paymentStatus, paymentType, amount, isShippingPaid } = formData;
        if (paymentStatus === 'Pendiente de pago') {
            return senderName && senderPhone && pickupAddress && packageType && receiverName && receiverPhone && deliveryAddress && paymentType && amount && (packageType !== 'otros' || packageDescription) && isShippingPaid;
        } else {
            return senderName && senderPhone && pickupAddress && packageType && receiverName && receiverPhone && deliveryAddress && (packageType !== 'otros' || packageDescription) && isShippingPaid;
        }
    };

    const generateOrderNumber = () => {
        const randomNumber = Math.floor(Math.random() * 900) + 100;
        return `BISA-O-${randomNumber}`;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) {
            setIsFormValid(false);
            return;
        }

        setIsFormValid(true);
        const orderNumber = generateOrderNumber();
        const newOrder = { ...formData, orderNumber, status: 'Recolección recibida' };

        try {
            await orderService.createOrder(newOrder);
            const templateParams = {
                ...formData,
                orderNumber,
                to_email: 'diegobiz0896@gmail.com,antoniobiz@hotmail.com'
            };
            await emailjs.send('service_65oleqw', 'template_3n1hnip', templateParams, 'CuXmCooSKe2MS3SAB');
            setIsModalOpen(true);
            setIsError(false);
            setOrderNumber(orderNumber);
            setFormData(initialFormData);
            setTouched({});
        } catch (err) {
            setIsModalOpen(true);
            setIsError(true);
            setIsFormValid(false);
        }
    };

    const handleGetCurrentLocation = () => {
        return new Promise((resolve, reject) => {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition((position) => {
                    const { latitude, longitude } = position.coords;
                    const updatedFormData = { ...formData, pickupAddress: `Lat: ${latitude}, Long: ${longitude}` };
                    setFormData(updatedFormData);
                    resolve();
                }, () => {
                    reject('Error al obtener la ubicación.');
                });
            } else {
                reject('Geolocalización no soportada por el navegador.');
            }
        });
    };

    const getBorderClass = (field) => {
        if (!touched[field] && isFormValid) return 'border-gray-300';
        return formData[field] ? 'border-green-500' : 'border-red-500';
    };

    const handleCopyOrderNumber = () => {
        navigator.clipboard.writeText(orderNumber)
            .then(() => {
                alert('Número de orden copiado al portapapeles');
            })
            .catch((err) => {
                console.error('Error al copiar el número de orden: ', err);
            });
    };

    return (
        <div className="bg-gray-100 text-black container mx-auto p-4 font-copperplate">
            <h1 className="text-2xl sm:text-4xl text-yellow-500 mb-4 text-center">Nueva Solicitud de Recolección</h1>
            {!isFormValid}
            <form onSubmit={handleSubmit} className="max-w-3xl mx-auto space-y-4">
                <div className="mb-5">
                    <label htmlFor="senderName" className="block mb-2 text-sm font-medium">Nombre de quien envía:</label>
                    <input
                        type="text"
                        name="senderName"
                        value={formData.senderName}
                        onChange={handleChange}
                        className={`shadow-sm bg-white border ${getBorderClass('senderName')} text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3 placeholder-gray-400 focus:ring focus:ring-opacity-50`}
                        required
                    />
                </div>
                <div>
                    <label htmlFor="senderPhone" className="block mb-2 text-sm font-medium">Teléfono de quien envía:</label>
                    <input
                        type="number"
                        name="senderPhone"
                        value={formData.senderPhone}
                        onChange={handleChange}
                        className={`shadow-sm bg-white border ${getBorderClass('senderPhone')} text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3 placeholder-gray-400 focus:ring focus:ring-opacity-50`}
                        required
                    />
                </div>
                <div>
                    <label className="block mb-2 text-sm font-medium">Dirección de recolección:</label>
                    <div className="flex space-x-2">
                        <input
                            type="text"
                            name="pickupAddress"
                            value={formData.pickupAddress}
                            onChange={handleChange}
                            className={`shadow-sm bg-white border ${getBorderClass('pickupAddress')} text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3 placeholder-gray-400 focus:ring focus:ring-opacity-50`}
                            required
                        />
                        <button
                            type="button"
                            onClick={handleGetCurrentLocation}
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg"
                        >
                            Usar ubicación actual
                        </button>
                    </div>
                </div>
                <div>
                    <label className="block mb-2 text-sm font-medium">Tipo de paquete:</label>
                    <select
                        name="packageType"
                        value={formData.packageType}
                        onChange={handleChange}
                        className={`shadow-sm bg-white border ${getBorderClass('packageType')} text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3 placeholder-gray-400 focus:ring focus:ring-opacity-50`}
                        required
                    >
                        <option value="">Seleccione una opción</option>
                        <option value="electrodomesticos">Electrodomésticos</option>
                        <option value="celulares">Celulares</option>
                        <option value="computadoras">Computadoras</option>
                        <option value="otros">Otros</option>
                    </select>
                </div>
                {formData.packageType === 'otros' && (
                    <div>
                        <label htmlFor="packageDescription" className="block mb-2 text-sm font-medium">Descripción de paquete:</label>
                        <input
                            type="text"
                            name="packageDescription"
                            value={formData.packageDescription}
                            onChange={handleChange}
                            className={`shadow-sm bg-white border ${getBorderClass('packageDescription')} text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3 placeholder-gray-400 focus:ring focus:ring-opacity-50`}
                            required
                        />
                    </div>
                )}
                <div>
                    <label className="block mb-2 text-sm font-medium">Nombre de quien recibe:</label>
                    <input
                        type="text"
                        name="receiverName"
                        value={formData.receiverName}
                        onChange={handleChange}
                        className={`shadow-sm bg-white border ${getBorderClass('receiverName')} text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3 placeholder-gray-400 focus:ring focus:ring-opacity-50`}
                        required
                    />
                </div>
                <div>
                    <label className="block mb-2 text-sm font-medium">Teléfono de quien recibe:</label>
                    <input
                        type="number"
                        name="receiverPhone"
                        value={formData.receiverPhone}
                        onChange={handleChange}
                        className={`shadow-sm bg-white border ${getBorderClass('receiverPhone')} text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3 placeholder-gray-400 focus:ring focus:ring-opacity-50`}
                        required
                    />
                </div>
                <div>
                    <label className="block mb-2 text-sm font-medium">Dirección de entrega:</label>
                    <div className="flex space-x-2">
                        <input
                            type="text"
                            name="deliveryAddress"
                            value={formData.deliveryAddress}
                            onChange={handleChange}
                            className={`shadow-sm bg-white border ${getBorderClass('deliveryAddress')} text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3 placeholder-gray-400 focus:ring focus:ring-opacity-50`}
                            required
                        />
                    </div>
                </div>
                <div>
                    <label className="block mb-2 text-sm font-medium">Referencias de lugar de entrega:</label>
                    <div className="flex space-x-2">
                        <input
                            type="text"
                            name="deliveryReferences"
                            value={formData.deliveryReferences}
                            onChange={handleChange}
                            className="shadow-sm bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3 placeholder-gray-400 focus:ring focus:ring-opacity-50"
                        />
                    </div>
                </div>
                <div>
                    <label className="block mb-2 text-sm font-medium">Estatus de pago:</label>
                    <select
                        name="paymentStatus"
                        value={formData.paymentStatus}
                        onChange={handleChange}
                        className={`shadow-sm bg-white border text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3 placeholder-gray-400 focus:ring focus:ring-opacity-50`}
                        required
                    >
                        <option value="Pagado">Pagado</option>
                        <option value="Pendiente de pago">Pendiente de pago</option>
                    </select>
                </div>
                {formData.paymentStatus === 'Pendiente de pago' && (
                    <>
                        <div>
                            <label className="block mb-2 text-sm font-medium">Tipo de pago:</label>
                            <select
                                name="paymentType"
                                value={formData.paymentType}
                                onChange={handleChange}
                                className={`shadow-sm bg-white border ${getBorderClass('paymentType')} text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3 placeholder-gray-400 focus:ring focus:ring-opacity-50`}
                                required
                            >
                                <option value="">Seleccione una opción</option>
                                <option value="Efectivo">Efectivo</option>
                                <option value="Transferencia">Transferencia</option>
                                <option value="Pago POS">Pago POS</option>
                            </select>
                        </div>
                        <div>
                            <label className="block mb-2 text-sm font-medium">Monto a cobrar:</label>
                            <input
                                type="number"
                                name="amount"
                                value={formData.amount}
                                onChange={handleChange}
                                className={`shadow-sm bg-white border ${getBorderClass('amount')} text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3 placeholder-gray-400 focus:ring focus:ring-opacity-50`}
                                required
                            />
                        </div>
                    </>
                )}
                <div>
                    <label className="block mb-2 text-sm font-medium">Envío ya fue cobrado:</label>
                    <select
                        name="isShippingPaid"
                        value={formData.isShippingPaid}
                        onChange={handleChange}
                        className={`shadow-sm bg-white border ${getBorderClass('isShippingPaid')} text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3 placeholder-gray-400 focus:ring focus:ring-opacity-50`}
                        required
                    >
                        <option value="">Seleccione una opción</option>
                        <option value="Si">Sí</option>
                        <option value="No">No</option>
                    </select>
                </div>
                <div className="flex justify-center">
                    <button type="submit" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg">
                        Generar nueva recolección
                    </button>
                </div>
            </form>
            {isModalOpen && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-white p-8 rounded-lg shadow-lg text-center">
                        <svg
                            className={`mx-auto h-12 w-12 ${isError ? 'text-red-500' : 'text-green-500'}`}
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d={isError ? "M6 18L18 6M6 6l12 12" : "M5 13l4 4L19 7"} />
                        </svg>
                        <h2 className="text-2xl mb-4">{isError ? 'Error al programar recolección' : `¡Recolección programada exitosamente! Número de orden: ${orderNumber}`}</h2>
                        {!isError && (
                            <div className="flex items-center justify-center space-x-2">
                                <span className="text-lg">{orderNumber}</span>
                                <ClipboardIcon className="h-6 w-6 text-blue-500 cursor-pointer" onClick={handleCopyOrderNumber} />
                            </div>
                        )}
                        <button
                            onClick={() => setIsModalOpen(false)}
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg mt-4"
                        >
                            Cerrar
                        </button>
                    </div>
                </div>
            )}
            {showInitialPopup && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-white p-8 rounded-lg shadow-lg text-center">
                        <h2 className="text-2xl mb-4">No olvides copiar el número de tu orden al momento de generar tu recolección</h2>
                        <img src="/images/orden.png" alt="Orden" className="mb-4" /> {/* Añadir la imagen aquí */}
                        <button
                            onClick={() => setShowInitialPopup(false)}
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg mt-4"
                        >
                            Entendido
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default CreateRequest;
