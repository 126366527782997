import React, { useState, useEffect } from 'react';
import orderService from '../api/orderService';
import { EyeIcon, TrashIcon } from '@heroicons/react/outline';

const stages = [
    'Recolección recibida',
    'Recolección gestionada',
    'Recolección asignada',
    'Camino a recolectar',
    'Producto recolectado',
    'Camino a entregarlo',
    'Producto entregado',
    'Orden finalizada'
];

const AdminOrders = () => {
    const [orders, setOrders] = useState([]);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
    const [orderToDelete, setOrderToDelete] = useState(null);

    useEffect(() => {
        const fetchOrders = async () => {
            try {
                const response = await orderService.getAllOrders();
                setOrders(response.data);
            } catch (err) {
                console.error('Error fetching orders:', err);
            }
        };
        fetchOrders();
    }, []);

    const handleViewOrder = async (orderNumber) => {
        try {
            const response = await orderService.getOrder(orderNumber);
            setSelectedOrder(response.data);
            setIsModalOpen(true);
        } catch (err) {
            console.error('Error fetching order:', err);
        }
    };

    const handleDeleteOrder = async () => {
        if (orderToDelete) {
            try {
                await orderService.deleteOrder(orderToDelete);
                setOrders((prevOrders) => prevOrders.filter((order) => order.orderNumber !== orderToDelete));
                setIsConfirmModalOpen(false);
                setOrderToDelete(null);
            } catch (err) {
                console.error('Error deleting order:', err);
            }
        }
    };

    const handleUpdateOrderStatus = async (orderNumber, newStatus) => {
        try {
            const response = await orderService.updateOrderStatus(orderNumber, newStatus);
            setSelectedOrder(response.data);
            setOrders((prevOrders) => prevOrders.map((order) => order.orderNumber === orderNumber ? response.data : order));
        } catch (err) {
            console.error('Error updating order status:', err);
        }
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedOrder(null);
    };

    const openConfirmModal = (orderNumber) => {
        setOrderToDelete(orderNumber);
        setIsConfirmModalOpen(true);
    };

    const closeConfirmModal = () => {
        setIsConfirmModalOpen(false);
        setOrderToDelete(null);
    };

    return (
        <div className="bg-gray-100 text-black container mx-auto p-4 font-copperplate">
            <h1 className="text-2xl sm:text-4xl text-yellow-500 mb-4 text-center">Administración de Pedidos</h1>
            <div className="max-w-3xl mx-auto space-y-4">
                <table className="min-w-full bg-white">
                    <thead className="bg-gray-800 text-white">
                    <tr>
                        <th className="w-1/4 py-2">Orden</th>
                        <th className="w-1/4 py-2">Quien creó la orden</th>
                        <th className="w-1/6 py-2">Ver</th>
                        <th className="w-1/6 py-2">Eliminar</th>
                    </tr>
                    </thead>
                    <tbody className="text-gray-700">
                    {orders.map((order) => (
                        <tr key={order.orderNumber}>
                            <td className="text-center py-2">{order.orderNumber}</td>
                            <td className="text-center py-2">{order.senderName}</td>
                            <td className="text-center py-2">
                                <EyeIcon
                                    className="h-6 w-6 text-blue-500 mx-auto cursor-pointer"
                                    onClick={() => handleViewOrder(order.orderNumber)}
                                />
                            </td>
                            <td className="text-center py-2">
                                <TrashIcon
                                    className="h-6 w-6 text-red-500 mx-auto cursor-pointer"
                                    onClick={() => openConfirmModal(order.orderNumber)}
                                />
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
                {isModalOpen && selectedOrder && (
                    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                        <div className="bg-white p-8 rounded-lg shadow-lg text-center">
                            <svg
                                className="mx-auto h-12 w-12 text-green-500"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                            </svg>
                            <h2 className="text-2xl mb-4">Detalles de la Orden</h2>
                            <p><strong>Nombre del Remitente:</strong> {selectedOrder.senderName}</p>
                            <p><strong>Teléfono del Remitente:</strong> {selectedOrder.senderPhone}</p>
                            <p><strong>Dirección de Recolección:</strong> {selectedOrder.pickupAddress}</p>
                            <p><strong>Tipo de Paquete:</strong> {selectedOrder.packageType}</p>
                            <p><strong>Nombre del Receptor:</strong> {selectedOrder.receiverName}</p>
                            <p><strong>Teléfono del Receptor:</strong> {selectedOrder.receiverPhone}</p>
                            <p><strong>Dirección de Entrega:</strong> {selectedOrder.deliveryAddress}</p>
                            <p><strong>Estatus del Pago:</strong> {selectedOrder.paymentStatus}</p>
                            <p><strong>Estatus Actual:</strong> {selectedOrder.status}</p>

                            <h3 className="text-lg font-bold mt-4 mb-2">Actualizar Estatus</h3>
                            <select
                                value={selectedOrder.status}
                                onChange={(e) => handleUpdateOrderStatus(selectedOrder.orderNumber, e.target.value)}
                                className="shadow-sm bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3 placeholder-gray-400 focus:ring focus:ring-opacity-50"
                            >
                                {stages.map((stage, index) => (
                                    <option key={index} value={stage}>{stage}</option>
                                ))}
                            </select>
                            <button
                                onClick={closeModal}
                                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg mt-4"
                            >
                                Cerrar
                            </button>
                        </div>
                    </div>
                )}
                {isConfirmModalOpen && (
                    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                        <div className="bg-white p-8 rounded-lg shadow-lg text-center">
                            <h2 className="text-2xl mb-4">Confirmar Eliminación</h2>
                            <p>¿Estás seguro de que deseas eliminar esta orden?</p>
                            <div className="flex justify-center mt-4 space-x-4">
                                <button
                                    onClick={handleDeleteOrder}
                                    className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-lg"
                                >
                                    Confirmar
                                </button>
                                <button
                                    onClick={closeConfirmModal}
                                    className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded-lg"
                                >
                                    Cancelar
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default AdminOrders;
