import React, { useState } from 'react';

const KeyValidation = ({ children }) => {
    const [key, setKey] = useState('');
    const [isValidKey, setIsValidKey] = useState(localStorage.getItem('user') === 'true');

    const handleValidateKey = () => {
        if (key === 'DA0829$') {
            localStorage.setItem('user', 'true');
            setIsValidKey(true);
            window.location.reload(); // Recargar la página después de validar correctamente
        } else {
            alert('Clave incorrecta. Intente de nuevo.');
            setKey('');
        }
    };

    if (!isValidKey) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
                <div style={{ textAlign: 'center', border: '1px solid #ccc', padding: '20px', maxWidth: '400px', width: '100%' }}>
                    <h2>AMBIENTE DE PRUEBAS/CERTIFICACION</h2>
                    <h3>Ingrese la clave</h3>
                    <input
                        type="password"
                        value={key}
                        onChange={(e) => setKey(e.target.value)}
                        placeholder="Ingrese la clave"
                        style={{ marginBottom: '10px', padding: '8px', width: '100%' }}
                    />
                    <br />
                    <button onClick={handleValidateKey} style={{ padding: '10px 20px', backgroundColor: '#007bff', color: '#fff', border: 'none', cursor: 'pointer' }}>Validar</button>
                </div>
            </div>
        );
    }

    return children;
};

export default KeyValidation;
