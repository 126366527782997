import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Contact from './pages/Contact';
import CreateRequest from './pages/CreateRequest';
import Prices from './pages/Prices';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Tracking from "./pages/Tracking";
import AdminOrders from "./pages/AdminOrders";
import KeyValidation from "./components/KeyValidation";

const App = () => {
    useEffect(() => {
        // Verificar y establecer 'user' en localStorage al cargar la aplicación por primera vez
        const userStatus = localStorage.getItem('user');
        if (userStatus === null) {
            localStorage.setItem('user', 'false'); // Inicializar 'user' como 'false' si no está definido
        }
    }, []); // Ejecutar solo una vez al cargar la aplicación

    return (
        <Router>
            <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
                <Navbar />
                <KeyValidation>
                <Routes style={{ flex: 1 }}>
                        <Route path="/" element={<Home />} />
                        <Route path="/contact" element={<Contact />} />
                        <Route path="/delivery/createRequest" element={<CreateRequest />} />
                        <Route path="/prices" element={<Prices />} />
                        <Route path="/request/tracking" element={<Tracking />} />
                        <Route path="/admin/orders" element={<AdminOrders />} />
                    </Routes>
                </KeyValidation>
            </div>
            <Footer />
        </Router>
    );
};

export default App;
