import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'; // Importa Link para crear enlaces internos
import ImageCarousel from '../components/ImageCarousel';
import {welcomeMessage} from "../utils/Glosary";

const Home = () => {
    const [welcomeText, setWelcomeText] = useState('');
    const showAdditionalText = welcomeText === welcomeMessage; // Controla la visibilidad del texto adicional

    useEffect(() => {
        let currentIndex = 0;
        const interval = setInterval(() => {
            if (currentIndex <= welcomeMessage.length) {
                setWelcomeText(welcomeMessage.slice(0, currentIndex));
                currentIndex++;
            } else {
                clearInterval(interval);
            }
        }, 10); // Intervalo de 200ms para la animación
        return () => clearInterval(interval); // Limpia el intervalo cuando el componente se desmonta
    }, []);

    return (
        <div className="text-center">
            <ImageCarousel />
            <h1 className="text-5xl font-bold mt-8">{welcomeText}</h1>
            {showAdditionalText && (
                <div className="mt-8 mx-auto max-w-md">
                    <p className="text-lg mb-4">Somos tu mejor opción para realizar tus envíos de manera segura, rápida y confiable, con depósitos de tus pedidos hasta el mismo día.</p>
                    <Link to="/delivery/CreateRequest">
                        <button
                            type="button"
                            className="text-white bg-gradient-to-r from-cyan-500 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-cyan-300 dark:focus:ring-cyan-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                            style={{ marginTop: '40px' }} // Agrega margen superior al botón
                        >
                            Quiero programar un envío
                        </button>
                    </Link>
                </div>
            )}
            {/* Otros contenidos de la página */}
        </div>
    );
};

export default Home;
