import React, { useState } from 'react';
import orderService from '../api/orderService';

const stages = [
    'Recolección recibida',
    'Recolección gestionada',
    'Recolección asignada',
    'Camino a recolectar',
    'Producto recolectado',
    'Camino a entregarlo',
    'Producto entregado',
    'Orden finalizada'
];

const Tracking = () => {
    const [orderNumber, setOrderNumber] = useState('');
    const [order, setOrder] = useState(null);

    const handleTrackOrder = async () => {
        try {
            const response = await orderService.getOrder(orderNumber);
            setOrder(response.data);
        } catch (err) {
            setOrder({ error: 'Número de orden no encontrado' });
        }
    };

    const getStageIndex = (status) => {
        return stages.indexOf(status);
    };

    return (
        <div className="bg-gray-100 text-black container mx-auto p-4 font-copperplate">
            <h1 className="text-2xl sm:text-4xl text-yellow-500 mb-4 text-center">Seguimiento de Solicitud</h1>
            <div className="max-w-3xl mx-auto space-y-4">
                <div>
                    <label htmlFor="orderNumber" className="block mb-2 text-sm font-medium">Número de Orden:</label>
                    <input
                        type="text"
                        name="orderNumber"
                        value={orderNumber}
                        onChange={(e) => setOrderNumber(e.target.value)}
                        className="shadow-sm bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3 placeholder-gray-400 focus:ring focus:ring-opacity-50"
                    />
                    <button
                        onClick={handleTrackOrder}
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg mt-4"
                    >
                        Rastrear Orden
                    </button>
                </div>
                {order && (
                    <div className="bg-white p-8 rounded-lg shadow-md mt-4 overflow-x-auto">
                        {order.error ? (
                            <p className="text-red-500">{order.error}</p>
                        ) : (
                            <>
                                <h2 className="text-xl font-bold mb-8">Estado de la Orden</h2>
                                <div className="flex items-center space-x-8">
                                    {stages.map((stage, index) => (
                                        <div key={index} className="flex flex-col items-center">
                                            <div className={`w-10 h-10 rounded-full ${getStageIndex(order.status) >= index ? 'bg-green-500' : 'bg-gray-300'}`}></div>
                                            {index < stages.length - 1 && (
                                                <div className={`w-1 h-12 sm:h-16 ${getStageIndex(order.status) >= index ? 'bg-green-500' : 'bg-gray-300'}`}></div>
                                            )}
                                            <p className="mt-4 text-center text-sm sm:text-base">{stage}</p>
                                        </div>
                                    ))}
                                </div>
                            </>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default Tracking;
