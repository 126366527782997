import React from 'react';

const Footer = () => {
    return (
        <footer className="bg-gray-900 text-white py-8">
            <div className="container mx-auto">
                <div className="flex flex-col md:flex-row items-center justify-center">
                    <p className="mb-4 md:mb-0 md:mr-4 text-center md:text-left">Una empresa de:</p>
                    <a href="https://serbizcar.com" target="_blank" rel="noopener noreferrer" className="md:ml-4 flex items-center">
                        <img src="/logos/serbizcarlogo.png" alt="Serbizcar Logo" className="h-12 w-auto cursor-pointer" />
                    </a>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
